// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { ParticipantDto } from '../_module/dto/participant.dto';
import { CurrentChangeDto } from '../event/dto/current-change.dto';
import { wsc } from '../wsc';
import { Observable } from 'rxjs';

export class PlayerModel {
  static start(
    eventId: string,
    action: 'RESTART' | 'CONTINUE' | null = null,
  ): Promise<{ eventSessionId: number }> {
    return wsc.execute('/ws/player/start', eventId, action) as any;
  }

  static end(eventId: string): Promise<void> {
    return wsc.execute('/ws/player/end', eventId) as any;
  }

  static join(
    eventId: string,
    avatar: { name: string; picture: string },
  ): Promise<void> {
    return wsc.execute('/ws/player/join', eventId, avatar) as any;
  }

  static fetchCurrent(eventId: string): Promise<CurrentChangeDto> {
    return wsc.execute('/ws/player/fetchCurrent', eventId) as any;
  }

  static listenCurrentChanged(eventId: string): Observable<CurrentChangeDto> {
    return wsc.subscribe('/ws/player/listenCurrentChanged', eventId) as any;
  }

  static updateCurrent(eventId: string, filmstripIndex: number): Promise<any> {
    return wsc.execute(
      '/ws/player/updateCurrent',
      eventId,
      filmstripIndex,
    ) as any;
  }

  static fetchParticipants(eventId: string): Promise<ParticipantDto[]> {
    return wsc.execute('/ws/player/fetchParticipants', eventId) as any;
  }

  static listenJoin(eventId: string): Observable<ParticipantDto> {
    return wsc.subscribe('/ws/player/listenJoin', eventId) as any;
  }

  static preview(eventId: string): Promise<any> {
    return wsc.execute('/ws/player/preview', eventId) as any;
  }

  static isJoined(eventId: string): Promise<boolean> {
    return wsc.execute('/ws/player/isJoined', eventId) as any;
  }

  static isValidJoined(eventId: string): Promise<boolean> {
    return wsc.execute('/ws/player/isValidJoined', eventId) as any;
  }
}
// 13ca62a10c2846c24e9e6aaaf28cc4ce9d2c3a6a1840a55c6a8e5eb52715fa75
