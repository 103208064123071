// 透過 @ay-nestjs/share 產生
/* eslint-disable */
import { wsc } from '../wsc';
import { Observable } from 'rxjs';

export class SpinnerModel {
  static listenIsWinner(): Observable<boolean> {
    return wsc.subscribe('/ws/spinner/listenIsWinner') as any;
  }

  static listenIsFinished(): Observable<boolean> {
    return wsc.subscribe('/ws/spinner/listenIsFinished') as any;
  }

  static setWinner(winnerIdentifiers: string[]): Promise<void> {
    return wsc.execute('/ws/spinner/setWinner', winnerIdentifiers) as any;
  }
}
// 685aefc28b9917f535cd00ac570d3ffc5919f044b73c5934aa67a350ad91ca36
